.fake-server {
    display: flex;
    align-items: center;
    flex: 1 1;
    height: 3.5rem;
    padding: 1em;
    border-radius: 0.3rem;
    opacity: 1;
}

.fake-server-icon {
    flex-shrink: 0;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: #292a2b;
    overflow: hidden;
}

.fake-server-name, .fake-server-button {
    border-radius: 0.3rem;
    background: #292a2b;
    overflow: hidden;
}

.fake-server-name {
    flex-shrink: 1;
    width: 16.8rem;
    height: 0.9rem;
    margin: 0 1em;
}

.fake-server-button {
    flex-shrink: 0;
    margin-left: auto;
    width: 7.8rem;
    height: 2.5rem;
}

.bip {
    background: linear-gradient(90deg,#292a2b,#333435,#292a2b);
    color: #fff;
    height: 100%;
    width: 30%;
    border-radius: 0.3rem;
    animation: 700ms linear 0ms infinite normal none running move;
}

.bip.small {
    width: 60%;
}

@keyframes move {
    0% {
        margin-left: -40%;
    }
    100% {
        margin-left: 100%;
    }
}
