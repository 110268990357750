.alerts-container {
    overflow-x: auto;
}

.alerts {
    width: 1080px;
    margin-bottom: 10rem;
}

.alerts thead tr th {
    font-weight: 600;
}

.alerts th {
    padding: 0.3rem;
}

.alerts textarea {
    margin-top: 0.5rem;
}

.variable-table {
    width: 100%;
    thead th {
        font-weight: 800;
    }
    th {
        border-bottom: 1px solid gray;
        width: 20%;
    }
    td {
        text-align: center;
    }
}

@media (min-width: 1080px) {
    .alerts {
        width: 100%;
        border-collapse: collapse
    }
}
