nav {
	z-index: 10;
	left: 0;
	right: 0;
	top: 0;
	padding: 0 10px;
	height: 6.25rem;
    	background-color: hsl(0, 0%, 4%);
	display: flex;
	justify-content: center;

	.logo {
		float: left;
		width: 50%;
		height: 100%;
		display: flex;
		align-items: center;
		font-size: 1.5rem;

		.img {
			margin-right: 1.25rem;
		}
	}

	ul.links {
		margin: 0;
		height: 100%;
		display: flex;
		align-items: center;

		li {
			margin: 0 10px;
			list-style: none;
		}
	}

	a {
		text-decoration: none;
		color: white;
	}

	.links-container a, button {
		display: block;
		padding: 1em;
		font-size: 1rem;
		font-weight: 400;

		&:after {
			bottom: 0;
			content: "";
			display: block;
			height: 2px;
			background: #fff;
			transition: width 0.3s ease 0s;
			width: 0;
			left: 0;
		}

		&:not(button):hover::after {
			width: 100%;
		}
	}

	#nav-toggle {
		position: absolute;
		top: -6.25rem;
	}

	.icon-burger {
		display: none;
		position: absolute;
		right: 5%;
		top: 3.1rem;
		transform: translateY(-50%);

		.line {
			top:3.1rem;
			width: 1.875rem;
			height:0.3rem;
			background-color: white;
			margin:0.3rem;
			border-radius: 0.188rem;
			transition: all .3s ease-in-out;
		}
	}
}

#dash-button {
	background-color: transparent;
	outline: none;
	padding: 0.625rem 0.75rem !important;
	border: 0.1rem solid rgb(255, 165, 0);
	color: white;
	border-radius:0.3rem;
	transition: all 180ms ease-out 0s;
	cursor: pointer;
	min-width: 8.75rem;
}

#dash-button:hover {
	background-color: rgb(255, 165, 0);
	color: white;
}

.logged-button {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 1020px) {
	nav {
		.logo {
			float: none;
			width: auto;
			padding-left: 0.625rem;
		}
		.links-container {
			float: none;
			position: fixed;
			left: 0;
			right: 0;
			bottom: 100%;
			width: auto;
			height: calc(100vh - 6.25rem);
			transition: cubic-bezier(0.39, 0.575, 0.565, 1);
			overflow: hidden;
		}
		.links {
			flex-direction: column;
			justify-content: space-evenly;
			background-color: #18191c;
			box-sizing: border-box;
		}
		.links a,button {
			font-size: 1.25rem;
		}
		#nav-toggle:checked ~ .links-container {
			bottom: 0rem;
		}
		.icon-burger {
			display: block;
		}
		#nav-toggle:checked ~ .icon-burger .line:nth-child(1) {
			transform: translateY(0.625rem) rotate(225deg);
		}
		#nav-toggle:checked ~ .icon-burger .line:nth-child(3) {
			transform: translateY(-0.625rem) rotate(-225deg);
		}
		#nav-toggle:checked ~ .icon-burger .line:nth-child(2) {
			opacity: 0;
		}
	}
}
