.logging-message-container {
    display: grid;
    column-gap: 3rem;
    row-gap: 3rem;
}

@media (min-width: 1200px) {
    .logging-message-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
