.tab-list {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
}

.tab-list-item {
    cursor: pointer;
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
}

.tab-list-active {
    border-bottom: solid 3px #7289da;
}
