.home {
    height: calc(70%);
}

.home-title {
    font-family: 'Inter' sans-serif;
    font-size: 1.5em;
}


.home-server-info {
    align-items: unset;
    justify-content: start;
    text-align: center;
    margin: 1rem;
}

.home-welcome {
    text-align: center;
    display: none;
}

.home-plugins {
    margin: 3em auto;
    max-width: 70rem;
}

.home-plugins-container {
    display: grid;
}

.home-plugins-item {
    cursor: pointer;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-top: 0.75rem;
    padding: 0.75rem;
    border-radius: 0.25rem;
    background-color: #313131;
    display: flex;
    box-shadow: 0 0 0.6rem rgb(20 21 23 / 74%);
}

.home-plugins-title {
    margin: 0.6rem;
    text-align: center;
}

.home-plugins-icon {
    padding-right: 1.5rem;
}

.home-plugins-item-title {
    font-size: 1.2em;
    font-weight: 500;
}

.home-plugins-item-content {
    color: #c0c0cc;
    font-size: 0.9em;
}

@media (min-width: 1200px) {
    .home-plugins-title {
        text-align: unset;
    }
    .home-title {
        font-size: 1.5em;
    }
    .home-welcome {
        display: block;
    }
    .home-plugins-container {
        grid-template-columns: repeat(3, 1fr);
    }
    .home-plugins-item {
        padding: 1.25rem;
        margin-top: 1.25rem;
        margin-left: 0.6rem;
        margin-right: 0.6rem;
    }
    .home-server-info {
        display: flex;
        align-items: center;
    }
}
