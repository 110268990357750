.button {
    border-radius: 10px;
    cursor: pointer;
    background-color: transparent;
    box-shadow: none;
    border: none;

    .bordered {
        color: white;
        border: 2px solid var(--orange);
    }

}
