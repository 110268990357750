.banner {
    background-color: #963131;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.banner-elements {
    padding: 2rem;
}

.banner-btn {
    margin-right: 1rem;
    border-radius: 4px;
    color: white;
    padding: 0 2rem;
    border: 2px solid white;
    transition: background-color 0.5s ease;

    &:hover {
        color: #963131;
        background-color: white;
    }
}

.feature-pres {
    display: flex;
    align-items: center;
    align-self: center;
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 1rem;
    flex-direction: column;
    img {
        width: 100%;
        height: auto;
    }
    div {
        height: auto;
    }
}

@media (min-width: 1080px) {
    .feature-pres {
        margin-top: 3rem;
        flex-direction: row;
        img {
            width: auto;
            height: 230px;
        }
        .right {
            margin-left: 2rem;
        }
        div {
            height: 250px;
        }
    }
}
